import React, { useState } from "react";
import './Admindashboard.css'; // Aquí puedes agregar los estilos

const Password = () => {
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [cargando, setCargando] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCargando(true);
    setMensaje("");

    // Realizar la solicitud fetch al archivo PHP
    try {
      const response = await fetch('https://contapro.com.mx/php/reset-password.php', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Enviamos el correo
      });

      const data = await response.json();

      if (data.success) {
        setMensaje("Revisa tu correo para restablecer tu contraseña.");
      } else {
        setMensaje("Hubo un error, intenta nuevamente.");
      }
    } catch (error) {
      setMensaje("Error al conectarse con el servidor.");
    } finally {
      setCargando(false);
    }
  };

  return (
    <div className="form-pass">
      <h2>Recuperar Contraseña</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Correo Electrónico:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={cargando}>
          {cargando ? "Enviando..." : "Recuperar Contraseña"}
        </button>
      </form>
      {mensaje && <p>{mensaje}</p>}
    </div>
  );
};

export default Password;