import React, { useState, useEffect, useContext } from "react";
import './Admindashboard.css'; // Aquí puedes agregar los estilos
import logo from './assets/Logo.png';
import { useMyState } from "./MyContext";
import ContadorVerificador from './ContadorDashboard';
import {Contador} from './ContadorDashboard';
import { useHistory } from 'react-router-dom';

const AdminVerificador = () =>{
  const isAuthenticated = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');
  const history = useHistory();

  if(!isAuthenticated){
    return history.push('/login');
  }
  if(!userRole){
    return history.push('/login');
  }
  if(userRole === 'contador'){
    return history.push('/contador');
  }
  if(userRole === 'usuario'){
    return history.push('/dashboard');
  }
  return AdminDashboard();
}
const AdminDashboard = () =>{
const {myState, setMyState, setMTabla2, setMTabla, setMyState2, panelabierto, setPanelabierto, setMisEmpresas} = useMyState();
const history = useHistory();
const [panel, setPanel] = useState('panel');

useEffect(() =>{
  if(panelabierto){
    setPanel('panel')
  }else{
    setPanel('panel2')
  }
}, [panelabierto])

const handleLogout = () => {
  // Eliminamos el token y el rol de localStorage al hacer logout
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  history.push('/login');
};


const menuAdmin = () =>{
    return(
      <>
        <div className="menu columna">
          <img src={logo}/>
          <div className="menu-list columna">
            <a className="selected" href="#" onClick={()=>{
              setMyState('Contadores');
              setMTabla('TablaUsuarios');
              setMyState2('Usuarios');
              setMTabla2('TablaUsuarios');
              }}><i class="fa-solid fa-circle-user"></i><span> Contadores</span></a><br></br>
              <a className="selected" href="" onClick={() =>{handleLogout()}}><i class="fa-solid fa-power-off"></i><span> Cerrar sesión</span></a>
          </div>
        </div>
      </>
    )
}
const menuRegresar = () =>{
  return(
    <>
      <div className="regresar-admin">
        <a onClick={() =>{
          setPanelabierto(true);
          setMyState('Contadores');
          setMTabla('TablaUsuarios');
          setMyState2('Usuarios');
          setMTabla2('TablaUsuarios');
          setPanel('panel')
          setMisEmpresas('EmpresasUsuarios')
        }}><i class="fa-solid fa-circle-chevron-left"/></a>
        </div>
    </>
  )
}
  return (
    <div className="dashboard">
        {panelabierto ? menuAdmin() : menuRegresar()}
      <div className={panel}>
        <div>
          

          {myState === 'Contadores' && <MostrarUsuarios />}
          {myState === 'PanelContador' && <Panelcontador/>}
        </div>
      </div>
    </div>
  )
}
const MostrarUsuarios = () =>{
    const {myTabla, setMTabla} = useMyState();

    return(
      <div>
        <div className="user-title">
          <h1>¡Bienvenido administra a tu equipo de trabajo!</h1>
          <a href="#" onClick={() => setMTabla('CrearUsuario')}>Agregar contador +</a>
        </div>
        {myTabla === 'TablaUsuarios' && <TablaUsuarios />}
        {myTabla === 'CrearUsuario' && <CrearUsuario />}
      </div>
    )
  
}
const TablaUsuarios = () =>{
    const [users, setUsers] = useState([]);
    const [setUserId] = useState([]);
    const {setMyState, setMyIdUser, setMyUser,setNcontador, setPanelabierto} = useMyState();
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Hacer la solicitud HTTP al archivo PHP
      fetch('https://contapro.com.mx/php/getcontadores.php')
        .then(response => response.json())
        .then(data => {
          setUsers(data)
          setLoading(false)
        })
        .catch(error => console.error("Error fetching users:", error));
    }, []);
  
    const handleUpdateClick = (id, name) => {
      setMyState('PanelContador');
      //setShowForm(true);
      setMyIdUser(id);
      setNcontador(id);
      setMyUser(name);
      console.log(name);
      setPanelabierto(false);
    };
    if (loading) {
      return <div>Loading...</div>; // Mostrar un mensaje de carga o un spinner mientras cargamos
    }
    return (
      <div>
        <table>
          <thead>
            <tr className="tabla-gradient">
              <th>Nombre</th>
              <th>Puesto</th>
              <th className="hide">Correo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.puesto}</td>
                <td className="hide">{user.mail}</td>
                <td><button onClick={() => handleUpdateClick(user.id, user.name)}>Ver panel</button></td>
              </tr>
            ))}
          </tbody>
        </table>
        
      </div>
    );
}
const CrearUsuario = () => {
  const {myTabla, setMTabla} = useMyState();
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const rol = "contador";
  const [password, setpassword] = useState("");
  const [puesto, setPuesto] = useState("");
  const [mensaje, setMensaje] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Enviar los datos al backend en PHP
    const response = await fetch('https://contapro.com.mx/php/create-contadores.php', {
      method: 'POST',
      body: new URLSearchParams({
        nombre,
        correo,
        password,
        puesto,
        rol
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    const result = await response.text();
    setMensaje(result); // Muestra la respuesta del servidor
    setMTabla('TablaUsuarios');
  };

  return (
    <div className="contenedor">
    <a onClick={() =>setMTabla('TablaUsuarios')}>Regresar</a>
    <div className="formulario">
      <h2>Crear Contador</h2>
      <form onSubmit={handleSubmit}>
        <div className="campo">
          <label>Nombre:</label>
          <input
            name="nombre"
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <div className="campo">
          <label>Correo:</label>
          <input
            name="correo"
            type="text"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            required
          />
        </div>
        {/*<div className="campo">
          <label>Rol:</label>
          <select
            name="rol"
            value={rol}
            onChange={(e) => setRol(e.target.value)}
            required
          >
            <option value="">Selecciona un rol</option>
            <option value="contador">Contador</option>
            <option value="usuario">Usuario</option>
          </select>
        </div>*/}
        <div className="campo">
          <label>Contraseña:</label>
          <input
          name="password"
            type="password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            required
          />
        </div>
        <div className="campo">
          <label>Puesto:</label>
          <input
            name="puesto"
            type="text"
            value={puesto}
            onChange={(e) => setPuesto(e.target.value)}
            required
          />
        </div>
        <button type="submit">Registrar</button>
      </form>
      {mensaje && <p>{mensaje}</p>}
    </div>
    </div>
  );
}
const Panelcontador = () =>{
  return <Contador/>
}
export default AdminVerificador;