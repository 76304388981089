import React, { createContext, useState, useContext, useEffect  } from "react";
const MyContext = createContext();
// Proveedor del contexto
export const MyProvider = ({ children }) => {

// Cargar los valores de localStorage si están disponibles
const getFromLocalStorage = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

const [myState, setMyState] = useState(getFromLocalStorage('myState', 'Contadores'));
const [myState2, setMyState2] = useState(getFromLocalStorage('myState2', 'Usuarios'));
const [myState3, setMyState3] = useState(getFromLocalStorage('myState3', 'MostrarEmpresasUsuarios'));
const [myTabla, setMTabla] = useState(getFromLocalStorage('myTabla', 'TablaUsuarios'));
const [myTabla2, setMTabla2] = useState(getFromLocalStorage('myTabla2', 'TablaUsuarios'));
const [myIdUser, setMyIdUser] = useState(getFromLocalStorage('myIdUser', ''));
const [myIdEmpresa, setMyIdEmpresa] = useState(getFromLocalStorage('myIdEmpresa', ''));
const [myName, setMyName] = useState(getFromLocalStorage('myName', ''));
const [myUser, setMyUser] = useState(getFromLocalStorage('myUser', ''));
const [nContador, setNcontador] = useState(getFromLocalStorage('nContador', ''));
const [estado, setEstado] = useState(getFromLocalStorage('estado', ''));
const [tipo, setTipo] = useState(getFromLocalStorage('tipo', ''));
const [misEmpresas, setMisEmpresas] = useState(getFromLocalStorage('misEmpresas', 'EmpresasUsuarios'));
const [panelabierto, setPanelabierto] = useState(true);
const [change, setChange] = useState(false);


// Guardar los valores en localStorage cada vez que se actualicen
useEffect(() => {
  //localStorage.setItem('myState', JSON.stringify(myState));
  //localStorage.setItem('myState2', JSON.stringify(myState2));
  localStorage.setItem('myTabla', JSON.stringify(myTabla));
  localStorage.setItem('myTabla2', JSON.stringify(myTabla2));
  localStorage.setItem('myIdUser', JSON.stringify(myIdUser));
  localStorage.setItem('myIdEmpresa', JSON.stringify(myIdEmpresa));
  localStorage.setItem('myName', JSON.stringify(myName));
  localStorage.setItem('myUser', JSON.stringify(myUser));
  localStorage.setItem('nContador', JSON.stringify(nContador));
  localStorage.setItem('estado', JSON.stringify(estado));
  localStorage.setItem('tipo', JSON.stringify(tipo));
  //localStorage.setItem('misEmpresas', JSON.stringify(misEmpresas));
}, [myTabla, myTabla2, myIdUser, myName, myName, nContador, estado, tipo, myIdEmpresa]);

return (
  <MyContext.Provider
    value={{
      myState, setMyState,
      myState2, setMyState2,
      myState3, setMyState3,
      myIdUser, setMyIdUser,
      myName, setMyName,
      myUser, setMyUser,
      myTabla, setMTabla,
      myTabla2, setMTabla2,
      nContador, setNcontador,
      estado, setEstado,
      tipo, setTipo,
      misEmpresas, setMisEmpresas,
      myIdEmpresa, setMyIdEmpresa,
      panelabierto, setPanelabierto,
      change, setChange,
    }}
  >
    {children}
  </MyContext.Provider>
);

};


// Hook personalizado para acceder al contexto
export const useMyState = () => useContext(MyContext);