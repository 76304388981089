import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom'; // Importamos history para redirigir
import './Login.css'; // Aquí puedes agregar los estilos
import  logo from './assets/Logo.png';
import { useMyState } from "./MyContext";

const LoginVerificador = () =>{
  const isAuthenticated = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');
  const history = useHistory();

  //console.log(isAuthenticated +" "+ userRole)

  if(!isAuthenticated){
    return Login();
  }
  if(!userRole){
    return Login();
  }
  if(userRole === 'admin'){
    return history.push('/admin');
  }
  if(userRole === 'contador'){
    return history.push('/contador');
  }
  if(userRole === 'usuario'){
    return history.push('/dashboard');
  }
  return Login();
}
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rol] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const {setNcontador, setMyUser, setMyIdUser, setEstado} = useMyState();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://contapro.com.mx/php/login.php', new URLSearchParams({
        username,
        password,
        rol
      }));

      //const data = await response.json();

      if (response.data.error) {
        const response2 = await axios.post('https://contapro.com.mx/php/admin.php', new URLSearchParams({
          username,
          password,
          rol
        }));
        if (response2.data.error) {
          setErrorMessage(response2.data.error);
        } else {
          //alert('Login successful! contador'+ response.data.name);
          
          // Si la respuesta no tiene errores, verificamos el rol del usuario
              if (response2.data.rol === 'admin') {
                //alert('admin')
                  localStorage.setItem('token', 'autenticado');
                  localStorage.setItem('role','admin');
                  history.push('/admin');  // Redirige a la pantalla de administrador
              } else if (response2.data.rol === 'contador') {
                 // alert('conta')
                  localStorage.setItem('token', 'autenticado');
                  localStorage.setItem('role','contador'); 
                  history.push('/contador');  // Redirige a la pantalla de usuario
                  setNcontador(response2.data.id);
                  setMyUser(response2.data.name);
              }
              
        }
      } else {
        //alert('Login successful! usuario'+ response.data.name);
        
        // Si la respuesta no tiene errores, verificamos el rol del usuario
           
                //alert('user')
                localStorage.setItem('token', 'autenticado');
                localStorage.setItem('role','usuario');
                history.push('/dashboard');  // Redirige a una pantalla predeterminada
                setMyUser(response.data.name);
                setMyIdUser(response.data.id);
                setEstado(response.data.status);
                
              
            
      }
    } catch (error) {
      setErrorMessage('Error connecting to the server. ' + error);
    }
  };

  return (
    <div className="login-container">
      {/*console.log(localStorage.getItem('role'))*/}
      <div className='franja-login'>
        <div className='responsive-login'>
          <h1>Ingresá</h1>
          <h4>Inicia sesión para continuar</h4>
        </div>
      </div>
      <div className='logo'>
        <img src={logo}/>
      </div>
      <form onSubmit={handleLogin}>
        <div className='form-int'>
          <h2>Inicio de sesión</h2>
          <div className="form-group">
            <label>Usuario</label>
            <input 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label>Contraseña</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button type="submit">Ingresar</button>
          <a href="/password">Recuperar contraseña</a>
        </div>
        
      </form>
    </div>
  );
};

export default LoginVerificador;
